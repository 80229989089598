var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "open-on-hover": "", "offset-x": "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b(
                      { staticClass: "d-inline-block" },
                      "div",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm.app.active_resource === "ncu"
        ? _c(
            "v-card",
            { attrs: { width: "400px" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "caption pb-1" },
                                [_vm._v("vCPU")]
                              ),
                              _c("v-card-text", { staticClass: "text-h6" }, [
                                _vm._v(_vm._s(_vm.currentNCU(_vm.app.ncu).cpu)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "caption pb-1" },
                                [_vm._v("Memory")]
                              ),
                              _c("v-card-text", { staticClass: "text-h6" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.toLocaleFixed(
                                      _vm.currentNCU(_vm.app.ncu).memory,
                                      0
                                    )
                                  ) + " GB"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { width: "400px" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _vm.currentNodePool(_vm.app.node_pool).cpu
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "caption pb-1" },
                                    [_vm._v("vCPU")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-h6" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currentNodePool(_vm.app.node_pool)
                                            .cpu
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentNodePool(_vm.app.node_pool).memory
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "caption pb-1" },
                                    [_vm._v("Memory")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-h6" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.toLocaleFixed(
                                            _vm.currentNodePool(
                                              _vm.app.node_pool
                                            ).memory,
                                            0
                                          )
                                        ) + " GB"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentNodePool(_vm.app.node_pool).gpu_type
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "caption pb-1" },
                                    [_vm._v("GPU Model")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-h6" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currentNodePool(_vm.app.node_pool)
                                            .gpu_type
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentNodePool(_vm.app.node_pool).vram
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "caption pb-1" },
                                    [_vm._v("GPU Memory")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-h6" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.toLocaleFixed(
                                            _vm.currentNodePool(
                                              _vm.app.node_pool
                                            ).vram,
                                            0
                                          )
                                        ) + " GB"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentNodePool(_vm.app.node_pool).ssd
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "caption pb-1" },
                                    [_vm._v("Temp storage")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-h6" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.toLocaleFixed(
                                            _vm.currentNodePool(
                                              _vm.app.node_pool
                                            ).ssd,
                                            0
                                          )
                                        ) + " GB"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }